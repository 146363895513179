/* eslint-disable @typescript-eslint/no-explicit-any */
import 'dayjs/locale/es'
import { UI } from '@constants/app'
import {
  Loader,
  NumberInput,
  NumberInputProps,
  SegmentedControl,
  SegmentedControlProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  Textarea,
  TextareaProps,
  TextInput,
  TextInputProps,
  CheckboxProps,
  Checkbox,
  Text,
} from '@mantine/core'
import {
  DateTimePicker,
  DatePickerInput,
  DateTimePickerProps,
  DatePickerInputProps,
} from '@mantine/dates'
import { capitalize } from '@utils/string'
import { $ } from '@utils/styles'

type InputTextInputProps = TextInputProps & {
  typeInput: 'text'
  uppercase?: boolean
  lowercase?: boolean
  capitalize?: boolean
}

type InputTextAreaProps = TextareaProps & {
  typeInput: 'textarea'
}

type InputNumberInputProps = NumberInputProps & {
  typeInput: 'number'
}

type InputSelectProps = SelectProps & {
  typeInput: 'select'
  loading?: boolean
}

type InputSegmentedControlProps = SegmentedControlProps & {
  typeInput: 'segmentedControl'
  label?: string
}

type InputSwitchProps = Omit<SwitchProps, 'labelPosition'> & {
  typeInput: 'switch'
  labelPosition?: 'left' | 'right' | 'top'
}

type InputCheckboxProps = CheckboxProps & {
  typeInput: 'checkbox'
}

type InputDateTimePickerProps = DateTimePickerProps & {
  typeInput: 'dateTimePicker'
}

type InputDateRangePickerProps = DatePickerInputProps & {
  typeInput: 'dateRangePicker'
}

type InputProps =
  | InputTextInputProps
  | InputNumberInputProps
  | InputSelectProps
  | InputSegmentedControlProps
  | InputTextAreaProps
  | InputSwitchProps
  | InputCheckboxProps
  | InputDateTimePickerProps
  | InputDateRangePickerProps

const Input = (props: InputProps) => {
  const { typeInput } = props

  const componentProps = {
    ...props,
    size: UI.Size,
    typeInput: undefined,
    uppercase: undefined,
    lowercase: undefined,
    capitalize: undefined,
    loading: undefined,
  } as any

  if (typeInput === 'number') {
    return <NumberInput {...componentProps} value={props.value === 0 ? undefined : props.value} />
  }

  if (typeInput === 'select') {
    return (
      <Select
        {...componentProps}
        rightSection={props.loading ? <Loader size={18} /> : props.rightSection}
      />
    )
  }

  if (typeInput === 'segmentedControl') {
    return (
      <div className={$(props.className, 'cd-w-full cd-flex cd-flex-col')}>
        {componentProps.label && <Text className="cd-text-sm">{componentProps.label}</Text>}
        <SegmentedControl {...componentProps} className="" />
      </div>
    )
  }

  if (typeInput === 'textarea') {
    return <Textarea {...componentProps} />
  }

  if (typeInput === 'switch') {
    if (componentProps.labelPosition === 'top') {
      const { label, ...rest } = componentProps

      return (
        <div className={$(props.className, 'cd-flex cd-flex-col cd-gap-y-[0.5rem]')}>
          <Text className="cd-text-sm">{label}</Text>
          <Switch {...rest} className="" />
        </div>
      )
    }
    return <Switch {...componentProps} />
  }

  if (typeInput === 'checkbox') {
    return <Checkbox {...componentProps} />
  }

  if (typeInput === 'dateTimePicker') {
    return <DateTimePicker {...componentProps} locale="es" />
  }

  if (typeInput === 'dateRangePicker') {
    return <DatePickerInput {...componentProps} locale="es" />
  }

  return (
    <TextInput
      {...componentProps}
      onChange={(e) => {
        if (props.uppercase) {
          e.target.value = e.target.value.toUpperCase()
        } else if (props.lowercase) {
          e.target.value = e.target.value.toLowerCase()
        } else if (props.capitalize) {
          e.target.value = capitalize(e.target.value)
        }
        if (props.onChange) props.onChange(e)
      }}
    />
  )
}

export default Input
