/* eslint-disable @typescript-eslint/no-explicit-any */
import { notifications } from '@mantine/notifications'
import libGeneratePDF, { Resolution } from 'react-to-pdf'

import {
  ProformaBaseType,
  GetProformasType,
  AccessoryItemType,
  BlockProformaType,
} from '@customTypes/proforma'
import { ReceiptBlockType } from '@customTypes/block'
import { RawMaterialReceiptType } from '@customTypes/receipt'

import { getBlockResumen } from './block'
import { getReceiptResumen } from './receipt'
import { getRawMaterialResumen } from './rawMaterial'

import { Logger } from './log'
import { ProformaSeinpro } from '@constants/proforma'
import { periodString } from './string'

export const cleanProformaName = (name: string) => {
  return name.replace(/\s/g, '_')
}

type SearchParams<T> = {
  params: GetProformasType
  data?: T[]
}

export const onFilterProforma = <T extends ProformaBaseType>(params: SearchParams<T>) => {
  const {
    params: { field, query },
    data = [],
  } = params

  if (field === 'date') {
    const { startDate, endDate } = params.params

    if (startDate && endDate) {
      return data.filter((item) => item.createdAt >= startDate && item.createdAt <= endDate)
    }

    if (startDate) {
      return data.filter((item) => item.createdAt >= startDate)
    }

    if (endDate) {
      return data.filter((item) => item.createdAt <= endDate)
    }
  }

  if (!query || !field || !data) {
    return data
  }

  if (field === 'sequential') {
    return data.filter((item) => item.sequential.toString().includes(query))
  }

  if (['name', 'description'].includes(field)) {
    const keyField = field as 'name' | 'description'
    return data.filter((item) => item[keyField].toLowerCase().includes(query.toLowerCase()))
  }

  if (field === 'client') {
    return data.filter((item) => item.client.name.toLowerCase().includes(query.toLowerCase()))
  }

  if (field === 'ruc') {
    return data.filter((item) => item.client.ruc?.toLowerCase().includes(query.toLowerCase()))
  }

  if (field === 'status') {
    return data.filter((item) => item.status.toLowerCase().includes(query.toLowerCase()))
  }

  return data
}

type ProformaDescriptionParams = {
  reduced?: boolean
  index?: number
  item: ProformaBaseType['items'][0]
  userRole: string
  handleInput?: (path: string, value: number | string) => void
}

export const getDescription = (params: ProformaDescriptionParams): JSX.Element | string => {
  const { item, reduced, handleInput, userRole, index } = params
  if (item.type === 'raw-material') {
    return getRawMaterialResumen({
      rawMaterial: item as RawMaterialReceiptType,
      handleInput,
      userRole,
      reduced,
      index,
    })
  } else if (item.type === 'receipt') {
    return getReceiptResumen({
      receipt: item as ReceiptBlockType,
      handleInput,
      userRole,
      reduced,
      index,
    })
  } else if (item.type === 'block') {
    return getBlockResumen({
      block: item as BlockProformaType,
      handleInput,
      userRole,
      reduced,
      index,
    })
  } else if (item.type === 'accessory') {
    return periodString((item as AccessoryItemType).description)
  }
  return ''
}

type ProformaTotalTypeParams = {
  item: ProformaBaseType['items'][0]
  unit?: boolean
  itemsProforma: ProformaBaseType['items']
}

export const getProformaTotal = (params: ProformaTotalTypeParams): number => {
  const { item, itemsProforma, unit } = params
  if (!item) return 0

  if (item.type === 'raw-material') {
    const rmCast = item as RawMaterialReceiptType
    return rmCast.value * (unit ? 1 : rmCast.quantity)
  } else if (item.type === 'receipt') {
    const receiptCast = item as ReceiptBlockType
    return (
      receiptCast.rawMaterials.reduce((acc, rm) => acc + rm.value, 0) *
      (unit ? 1 : receiptCast.quantity)
    )
  } else if (item.type === 'block') {
    const blockCast = item as BlockProformaType
    return (
      blockCast.receipts.reduce(
        (acc, re) => acc + re.rawMaterials.reduce((acc, rm) => acc + rm.value, 0),
        0,
      ) * (unit ? 1 : blockCast.quantity)
    )
  } else if (item.type === 'accessory') {
    const accCast = item as AccessoryItemType
    const linkedItem = itemsProforma.find((i) => i.uid === accCast.uidItem)

    if (!linkedItem) return 0

    return (
      (accCast.quantity / 100) *
      getProformaTotal({
        item: linkedItem,
        itemsProforma,
        unit,
      })
    )
  }

  return 0
}

export const generatePDF = async (ref: any, filename: string) => {
  try {
    await libGeneratePDF(ref, {
      filename: cleanProformaName(filename),
      method: 'open',
      resolution: Resolution.HIGH,
      page: { format: 'A4', orientation: 'portrait' },
    })

    notifications.show({
      color: 'green',
      title: 'PDF Generado',
      message: 'PDF generado correctamente',
    })
  } catch (error) {
    Logger.error('Error generating PDF', error)
    notifications.show({
      color: 'red',
      title: 'Error',
      message: 'Error generando PDF',
    })
  }
}

export const getSequentialStr = (seq: number) => {
  const { sequential } = ProformaSeinpro

  const seqStr = seq.toString()

  return sequential.substring(0, sequential.length - seqStr.length) + seqStr
}
